
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    /*
| Developed by Starton
| Filename : login.tsx
| Author : Calixte DE TOURRIS (calixte@starton.com)
*/
import { GetServerSideProps } from 'next';
import useTranslation from 'next-translate/useTranslation';
import { NextSeo } from 'next-seo';
import React from 'react';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { UrlsConfig } from '@/config/urls/urls.config';
import { useMaintenances } from '@/services/fapi';
import { CollapsableBox } from '@/components/common/motion/CollapsableBox';
import { CreateAccountButtons } from '@/components//auth/pages/create-account';
import { Login } from '@/components//auth/pages/login/Login';
import { StartonCTAQuestion } from '@/components//auth/common/StartonCTAQuestion';
import { createStartonAuthPageServerSideProps, StartonPageFactory } from '@/components/ssr';
/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export type LoginState = 'buttons' | 'form';
/*
|--------------------------------------------------------------------------
| Page
|--------------------------------------------------------------------------
*/
const LoginPage = StartonPageFactory(() => {
    const { t } = useTranslation();
    const [state, setState] = React.useState<LoginState>('buttons');
    const { data: maintenancesData } = useMaintenances();
    // Render
    // ----------------------------------------------------------------------------
    return (<React.Fragment>
			<NextSeo title={t('auth/login:seo.title')} description={t('auth/login:seo.description')}/>
			<motion.div initial={{ opacity: 0, y: 100 }} animate={{ opacity: 1, y: 0 }} style={{
            width: '100%',
            maxWidth: 500,
            display: 'flex',
            flexDirection: 'column',
        }}>
				<Box display="flex" flexDirection="column" gap={3}>
					<Typography variant="h2">{t('auth/login:title')}</Typography>
					<CollapsableBox open={state === 'buttons'} removeGap={-3}>
						<CreateAccountButtons eventType="oidc-login" onEmailClick={() => setState('form')}/>
					</CollapsableBox>
					<CollapsableBox open={state === 'form'} removeGap={-3}>
						<Login setState={setState}/>
					</CollapsableBox>
					<StartonCTAQuestion text={t('auth/login:createAnAccount.text')} cta={{
            text: t('auth/login:createAnAccount.buttonText'),
            link: UrlsConfig.auth.createAccount,
            disabled: {
                active: maintenancesData?.data?.attributes?.authRegister?.activated ?? false,
                message: maintenancesData?.data?.attributes?.authRegister?.content,
            },
            dataTestId: 'create-account',
        }}/>
				</Box>
			</motion.div>
		</React.Fragment>);
});
/*
|--------------------------------------------------------------------------
| Page configurations
|--------------------------------------------------------------------------
*/
LoginPage.layout = 'public';
/*
|--------------------------------------------------------------------------
| SSR
|--------------------------------------------------------------------------
*/
const getServerSideProps: GetServerSideProps = createStartonAuthPageServerSideProps();
export default LoginPage;

    async function __Next_Translate__getServerSideProps__19205afe4f9__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/auth/login',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__19205afe4f9__ as getServerSideProps }
  